<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button-group>
          <el-button size="small" type="info" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
        </el-button-group>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`price_consult_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @row-dblclick="dbClickJp"
        @getTableData="getDequsNow"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import { getDate, getDateNoTime } from '@assets/js/dateUtils';
import { debounce } from 'lodash';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { priceConsultAPI } from '@api/modules/priceConsult';
import pubPagination from '@/components/common/pubPagination.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'PriceConsultList',
  components: {
    SearchTable,
    pubPagination
  },
  mixins: [myMixin],
  data() {
    return {
      tableProperties: [
        { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
        { prop: 'price_consult_no', label: '咨询单编号', itemType: 'input', input: false, widthAuto: true },
        { prop: 'cust_abbr', label: '客户简称', itemType: 'input', widthAuto: true },
        { prop: 'series_name', label: '系列名称', itemType: 'input', widthAuto: true },
        { prop: 'dept_name', label: '销售部门', itemType: 'input', widthAuto: true },
        { prop: 'stff_name', label: '核价人', itemType: 'input', widthAuto: true },
        {
          prop: 'table_top_status',
          label: '报价状态',
          itemType: 'select',
          options: [
            { value: 0, label: '未报价' },
            { value: 1, label: '报价中' },
            { value: 2, label: '已报价' }
          ],
          formatter: val => {
            if (val === 0) {
              return '未报价';
            } else if (val === 1) {
              return '报价中';
            } else if (val === 2) {
              return '已报价';
            }
          },
          widthAuto: true
        },
        {
          prop: 'create_time',
          label: '录入日期',
          itemType: 'date',
          sortable: true,
          formatter: val => getDate(val, false),
          input: false,
          widthAuto: true
        },
        { prop: 'inquiry_remark', label: '外销备注', itemType: 'input', input: false, widthAuto: true },
        {
          prop: 'status',
          label: '单据状态',
          itemType: 'select',
          options: [
            { value: 0, label: '草拟' },
            { value: 1, label: '在批' },
            { value: 2, label: '生效' }
          ]
        }
      ],
      searchForm: {},
      loadFlag: true,
      dequStffList: [],
      custList: [],
      totalPage: 0,
      tableData: [],
      tableData1: [],
      multiSelection: [],
      stffUserList: [],
      statusList: [
        { id: 0, label: '草拟' },
        { id: 1, label: '在批' },
        { id: 2, label: '生效' }
      ],
      ShipmentStatusList: [
        { id: 0, label: '未出运/部分出运' },
        { id: 1, label: '已出运' }
      ],
      custDportList: [],
      deleteCopy: [],
      clickFlag: false,
      sumProd_tvol: 0,
      btn: {}
    };
  },
  created() {},
  mounted() {
    this.initData();
  },
  methods: {
    getDateNoTime,
    initData() {
      this.getPcst();
    },
    //获取List
    getPcst() {
      let searchForm = this.$refs.multiTable.searchForm;
      get(priceConsultAPI.getPriceConsult, searchForm).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.priceConsultList;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          this.loadFlag = true;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message.error({ message: mg, type: tp });
        }
      });
    },
    //双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/price_consult_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.price_consult_id
          })
        )
      });
    },
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      this.getPcst();
    }, 1000),
    // 刷新
    buttonRefresh() {
      this.loadFlag = true;
      this.$refs.multiTable.resetFields();
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getPcst();
    },
    handleClick(e) {
      // 阻断冒泡上级元素事件
      e.cancelBubble = true;
    },
    //升序 降序
    sortChange({ order, prop }) {
      if (!order) {
        this.searchForm.column = '';
        this.searchForm.order = '';
        return;
      }
      this.searchForm.column = prop;
      this.searchForm.order = order.substring(0, order.indexOf('ending'));
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1).then(res => {
        if (res.data.code === 0) {
          this.stffUserList = res.data.data;
          return;
        }
        this.$message.error(res.data.msg);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_posi_o {
  position: relative;
}

::v-deep .vd_posi_t {
  position: absolute;
  top: 10px;
  left: 30px;
  font-size: 24px;
  color: red;
}

::v-deep .fixed-cell {
  background-color: #ccc !important;
}
.vd_dirc {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.vd_sortColor {
  color: rgb(0, 196, 250);
}
</style>
